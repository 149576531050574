<template>
  <Div :darkMode="darkMode">
    <Layout>
          <Sider
            :width="280"
            :style="{
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100vh',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
              position: 'fixed'
            }"
            :collapsed="collapsed || !sideMenuVisible"
            :theme="!darkMode ? 'light' : 'dark'"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <a to="#" v-if="!collapsed">
                <a-avatar
                  :size="120"
                  :src="require(`@/static/img/GCE-logo-white.png`)"
                  @click="goToHomePage"
                />
              </a>
              <a-row v-else justify="center" align="middle">
                <a-col>
                  <a to="#">
                    <a-avatar :size="50" :src="require(`@/static/img/logo-menu.png`)" @click="goToHomePage"/>
                  </a>
                </a-col>
              </a-row>
              <AsideItems
                :toggleCollapsed="toggleCollapsedMobile || !sideMenuVisible"
                :topMenu="topMenu"
                :rtl="rtl"
                :darkMode="darkMode"
                :events="onEventChange"
                @hideSideMenu="sideMenuVisible = false"
                @showSideMenu="sideMenuVisible = true"
              />
            </perfect-scrollbar>
          </Sider>
        <!-- </a-col>
        <a-col :xs="20" :lg="20" :xl="20" :xxl="20"> -->
          <Layout class="atbd-main-layout">
              <!-- position: 'fixed', -->
            <Header
            :style="{
              top: 0,
              width: '100%'
            }"
            >
              <a-row>
                <a-col :lg="!topMenu ? 4 : 3" :sm="6" :xs="12" class="align-center-v navbar-brand">
                  <sdButton v-if="sideMenuVisible && (!topMenu || innerWidth <= 991)" @click="toggleCollapsed"
                  type="white" color="white">
                    <sdFeatherIcons type="menu" color="black" />
                  </sdButton>
                  <router-link :class="topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'" to="/">
                    GCE
                  </router-link>
                </a-col>

                <a-col :lg="!topMenu ? 14 : 15" :md="8" :sm="0" :xs="0" />

                <a-col :lg="6" :md="10" :sm="0" :xs="0" v-if="hide">
                  <AuthInfo />
                </a-col>
                <a-col :style="{ position: 'static' }" :md="0" :sm="18" :xs="12">
                  <div class="mobile-action">
                    <a class="btn-auth" @click="onShowHide(hide)" href="#">
                      <sdFeatherIcons type="more-vertical" />
                    </a>
                  </div>
                </a-col>
              </a-row>
            </Header>
            <div class="header-more" v-if="!hide">
              <a-row>
                <a-col :md="0" :sm="24" :xs="24">
                  <div class="small-screen-headerRight">
                    <SmallScreenAuthInfo :hide="hide" :darkMode="darkMode">
                      <AuthInfo :rtl="rtl" />
                    </SmallScreenAuthInfo>
                  </div>
                </a-col>
              </a-row>
            </div>
            <Content>
              <div v-if="isLoading" class="spin">
                <a-spin />
              </div>
              <app-ticket v-if="showTicketButton" />
              <div v-show="!isLoading">
                <router-view />
              </div>
            <Footer
              class="admin-footer"
              :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
              v-if="false"
            >
              <a-row>
                <a-col :md="12" :xs="24">
                  <span class="admin-footer__copyright">2021 © SovWare</span>
                </a-col>
                <a-col :md="12" :xs="24">
                  <div class="admin-footer__links">
                    <a to="#">About</a>
                    <a to="#">Team</a>
                    <a to="#">Contact</a>
                  </div>
                </a-col>
              </a-row>
            </Footer>
            </Content>
          </Layout>
    </Layout>
  </Div>
</template>
<script>
import {Layout} from 'ant-design-vue';
import {Div, SmallScreenAuthInfo} from './style';
import AuthInfo from '../components/utilities/auth-info/info';
import AsideItems from './Aside';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import {
  computed, defineComponent, inject, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import {ABILITY_TOKEN} from '@casl/vue';
import ability from '@/core/plugins/ability';
import _ from 'lodash';
import {useRouter} from 'vue-router';

const {
  Header,
  Footer,
  Sider,
  Content
} = Layout;

export default defineComponent({
  name: 'WithAdminLayout',
  components: {
    Div,
    Header,
    Layout,
    Footer,
    Sider,
    Content,
    SmallScreenAuthInfo,
    AuthInfo,
    AsideItems,
    PerfectScrollbar
  },
  setup() {
    const collapsed = ref(true);
    const hide = ref(true);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);
    const sideMenuVisible = ref(true);

    const {
      dispatch, getters, state
    } = useStore();
    const router = useRouter();

    const rtl = computed(() => state.layout.rtl);
    const darkMode = computed(() => state.layout.darkMode);
    const topMenu = computed(() => state.layout.topMenu);
    const isLoading = computed(() => state.layout.loading);

    collapsed.value = window.innerWidth <= 1200 && true;

    const toggleCollapsed = (e) => {
      e.preventDefault();
      collapsed.value = !collapsed.value;
    };
    const handleSearchHide = (search) => {
      searchHide.value = !search;
      hide.value = true;
    };
    const onShowHide = (h) => {
      hide.value = !h;
      searchHide.value = true;
    };
    const toggleSearch = () => {
      activeSearch.value = !activeSearch.value;
    };

    const toggleCollapsedMobile = () => {
      if (innerWidth <= 990) {
        collapsed.value = !collapsed.value;
      }
    };

    const onRtlChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
      dispatch('changeRtlMode', true);
    };

    const onLtrChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
      dispatch('changeRtlMode', false);
    };

    const modeChangeDark = () => {
      dispatch('changeLayoutMode', true);
    };

    const modeChangeLight = () => {
      dispatch('changeLayoutMode', false);
    };

    const modeChangeTopNav = () => {
      dispatch('changeMenuMode', true);
    };

    const modeChangeSideNav = () => {
      dispatch('changeMenuMode', false);
    };

    const $ability = inject(ABILITY_TOKEN);
    const userPermissions = computed(() => getters.userPermissions);
    watch(userPermissions, async (value) => {
      if (!_.isEmpty(value)) {
        $ability.update(ability(value).rules);
      }
    }, {deep: true, immediate: true});

    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');
    const showTicketButton = computed(() => (!isBackOffice.value && !_.includes(['AgentQuote', 'AgentShipmentDetails', 'ClientShipmentQuote', 'ClientShipmentDetails'], router.currentRoute.value.name)));

    const goToHomePage = () => {
      router.push('/');
    };
    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav
    };
    return {
      toggleCollapsed,
      toggleCollapsedMobile,
      handleSearchHide,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      isLoading,
      sideMenuVisible,
      showTicketButton,
      goToHomePage
    };
  }
});
</script>
<style>
.ps {
  height: calc(100vh - 100px);
}
.atbd-main-layout {
  margin-top: 0px!important
}
</style>
