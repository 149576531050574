export default [
  {
    key: 'ClientShipmentRequests',
    icon: 'package',
    i18n: 'layout.shipmentRequest.index',
    pathName: 'ClientShipmentRequests',
    abilitySubject: 'shipmentRequest'
  },
  {
    key: 'BankAccountInformation',
    icon: 'credit-card',
    i18n: 'layout.bankAccountInformation',
    pathName: 'BankAccountInformation',
    abilitySubject: 'bankAccount'
  },
  {
    key: 'ClientCompany',
    icon: 'globe',
    i18n: 'layout.companies.title',
    children: [
      {
        key: 'ClientCompanyProfile',
        i18n: 'layout.companies.profile',
        pathName: 'ClientCompanyProfile',
        abilitySubject: 'company'
      },
      {
        key: 'ClientUsers',
        i18n: 'views.users.title',
        icon: 'users',
        pathName: 'ClientUsers',
        abilitySubject: 'user'
      }
    ]
  },
  {
    key: 'ClientTickets',
    i18n: 'layout.ticket.title',
    icon: 'help-circle',
    pathName: 'ClientTickets',
    abilitySubject: 'ticket'
  }
];
