export default [
  {
    key: 'BackOfficeSystemParams',
    icon: 'sliders',
    i18n: 'layout.systemSettings.title',
    children: [
      {
        key: 'BackOfficeSystemConfigurations',
        i18n: 'layout.systemSettings.systemConfigurations',
        pathName: 'BackOfficeSystemConfigurations',
        abilitySubject: 'systemConfiguration'
      },
      {
        key: 'BackOfficeDynamicLists',
        i18n: 'layout.systemSettings.dynamicLists',
        pathName: 'BackOfficeDynamicLists',
        abilitySubject: 'dynamicList'
      },
      {
        key: 'BackOfficeSanctionedCountries',
        i18n: 'layout.systemSettings.sanctionedCountries',
        pathName: 'BackOfficeSanctionedCountries',
        abilitySubject: 'sanctionedCountry'
      }
    ]
  },
  {
    key: 'PriceList',
    icon: 'dollar-sign',
    i18n: 'layout.priceLists.title',
    abilitySubject: 'priceCategoryItem',
    children: [
      {
        key: 'BackOfficePriceCategories',
        i18n: 'layout.priceLists.categories',
        pathName: 'BackOfficePriceCategories',
        abilitySubject: 'priceCategoryItem'
      },
      {
        key: 'BackOfficeSuggestedPrices',
        i18n: 'layout.priceLists.suggested',
        pathName: 'BackOfficeSuggestedPrices',
        abilitySubject: 'suggestedPrice'
      }
    ]
  },
  {
    key: 'BackOfficeUsers',
    icon: 'users',
    i18n: 'layout.users.title',
    pathName: 'BackOfficeUsers',
    abilitySubject: 'user'
  },
  {
    key: 'Companies',
    icon: 'globe',
    i18n: 'layout.companies.index',
    abilitySubject: 'user',
    children: [
      {
        key: 'BackOfficeCompaniesAgent',
        i18n: 'layout.companies.agent',
        pathName: 'BackOfficeAgentCompanies',
        abilitySubject: 'company'
      },
      {
        key: 'BackOfficeCompaniesClient',
        i18n: 'layout.companies.client',
        pathName: 'BackOfficeClientCompanies',
        abilitySubject: 'company'
      }
    ]
  },
  {
    key: 'BackOfficeShipments',
    icon: 'package',
    i18n: 'layout.shipmentRequest.shipments',
    pathName: 'BackOfficeShipments',
    abilitySubject: 'shipment'
  },
  {
    key: 'BackOfficeTickets',
    i18n: 'layout.ticket.title',
    icon: 'help-circle',
    pathName: 'BackOfficeTickets',
    abilitySubject: 'ticket'
  },
  {
    key: 'BackOfficeReports',
    i18n: 'layout.report.index',
    icon: 'book',
    pathName: 'BackOfficeReports',
    abilitySubject: 'report'
  }
];
