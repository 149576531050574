export default [
  {
    key: 'AgentShipments',
    pathName: 'AgentShipments',
    icon: 'package',
    i18n: 'layout.shipmentRequest.shipments',
    abilitySubject: 'shipmentRequest'
  },
  {
    key: 'AgentQuotes',
    pathName: 'AgentQuotes',
    icon: 'dollar-sign',
    i18n: 'layout.shipmentQuote.index',
    abilitySubject: 'shipmentQuote'
  },
  {
    key: 'AgentCompany',
    icon: 'globe',
    i18n: 'layout.companies.title',
    children: [
      {
        key: 'AgentCompanyProfile',
        i18n: 'layout.companies.profile',
        pathName: 'AgentCompanyProfile',
        abilitySubject: 'company'
      },
      {
        key: 'AgentUsers',
        i18n: 'views.users.title',
        icon: 'users',
        pathName: 'AgentUsers',
        abilitySubject: 'user'
      },
      {
        key: 'AgentQuoteTemplate',
        pathName: 'AgentQuoteTemplate',
        icon: 'file-text',
        i18n: 'layout.shipmentQuote.template',
        abilitySubject: 'shipmentQuoteTemplate'
      }
    ]
  },
  {
    key: 'AgentTickets',
    i18n: 'layout.ticket.title',
    icon: 'help-circle',
    pathName: 'AgentTickets',
    abilitySubject: 'ticket'
  }
];
