<template>
  <div class="notification-bell">
    <sd-popover ref="notificationsPopOver" placement="bottomLeft" action="click">
      <a-badge :offset="[-8, -5]"  :count="notifications.badge">
        <a to="#" class="head-example">
          <sd-feather-icons type="bell" size="20" />
        </a>
      </a-badge>
      <template v-slot:content>
        <atbd-top-dropdown class="atbd-top-dropdwon">
          <sd-heading as="h5" class="atbd-top-dropdwon__title">
            <span class="title-text">{{$t('views.notifications.title')}}</span>
            <a-badge class="badge-success" :count="notifications.badge || 0" />
            <a v-if="notifications.badge > 0" class="mark-read"
              @click="readAll" :class="markAllReadLoading ? 'disabled-link' : ''">
              {{$t('views.notifications.markAllAsRead')}}
              <LoadingOutlined v-if="markAllReadLoading" />
            </a>
          </sd-heading>
          <perfect-scrollbar :options="{
              wheelSpeed: 1,
              swipeEasing: true,
              suppressScrollX: true,
              wheelPropagation: false
            }" >
            <ul>
              <li v-for="notification in notifications.data" :key="notification._jv.id">
                <a @click="goToNotification(notification)">
                  <div class="atbd-top-dropdwon__content notifications">
                    <div v-if="notification.notifiableType == 'Shipment'"
                         class="notification-icon bg-primary">
                      <sd-feather-icons type="truck" />
                    </div>
                    <div v-else-if="notification.notifiableType == 'ShipmentRequest'"
                         class="notification-icon status warning">
                      <sd-feather-icons type="package" />
                    </div>
                    <div v-else-if="notification.notifiableType == 'ShipmentQuote'"
                         class="notification-icon status Success">
                      <sd-feather-icons type="dollar-sign" />
                    </div>
                    <div v-else type="share-2"
                         class="notification-icon bg-secondary">
                      <sd-feather-icons type="share-2" />
                    </div>
                    <div class="notification-content d-flex">
                      <div class="notification-text">
                        <sdHeading as="h5"> {{notification.messageContent}} </sdHeading>
                        <p>{{moment(new Date(notification.createdAt)).fromNow()}}</p>
                      </div>
                      <div v-show="!notification.read" class="notification-status">
                        <a-badge dot />
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <vue-eternal-loading :load="load" :is-initial="true">
              <template #loading>
                <div class="text-center">
                  <a-spin/>
                </div>
              </template>
              <template #no-more>
                <div/>
              </template>
              <template #no-results>
                <div/>
              </template>
            </vue-eternal-loading>
          </perfect-scrollbar>
        </atbd-top-dropdown>
      </template>
    </sd-popover>
  </div>
</template>
<script>
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import {AtbdTopDropdown} from './auth-info-style';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import moment from 'moment';
import {LoadingOutlined} from '@ant-design/icons-vue';

import {
  computed, onMounted, ref, onUnmounted
} from 'vue';

export default {
  name: 'Notification',
  components: {
    AtbdTopDropdown,
    PerfectScrollbar,
    LoadingOutlined
  },
  setup(props, context) {
    const {
      getters, dispatch, state
    } = useStore();
    const router = useRouter();
    const isClient = computed(() => state.session.userType === 'ClientUser');
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');

    const notificationsPopOver = ref();
    const currentUserId = state.session.currentUser._jv.id;
    const getAndSubscribeToNotificationChannel = () => {
      dispatch('getNotifications', {badgeOnly: true});
      dispatch('subscribeToNotificationChannel', currentUserId);
    };
    getAndSubscribeToNotificationChannel();
    const notifications = computed(() => getters.notifications);
    const load = async function({loaded}) {
      dispatch('getNotifications', {badgeOnly: false}).then(() => {
        return loaded(notifications.value.totalPages - notifications.value.page);
      });
    };

    const goToNotification = (notification) => {
      const ticketRoute = ref();
      if (!notification.read) {
        dispatch('readNotification', notification._jv.id);
      }
      switch (notification.notifiableType) {
      case 'ShipmentRequest':
        router.push({
          name: isClient.value ? 'ClientShipmentRequestDetails' : 'AgentShipmentRequestDetails',
          params: {shipmentRequestId: notification.notifiableId}
        });
        break;
      case 'ShipmentQuote':
        if (isBackOffice.value) {
          router.push({
            name: 'BackOfficeShipmentQuotes',
            params: {shipmentId: notification.shipmentId}
          });
        } else {
          router.push({
            name: isClient.value ? 'ClientShipmentQuote' : 'AgentShipmentQuote',
            params: {
              shipmentRequestId: notification.shipmentRequestId,
              shipmentId: notification.shipmentId,
              quoteId: notification.notifiableId
            }
          });
        }
        break;
      case 'Shipment':
        if (isBackOffice.value) {
          router.push({
            name: 'BackOfficeShipmentDetails',
            params: {shipmentId: notification.notifiableId},
            query: {shipmentRequestId: notification.shipmentRequestId}
          });
        } else {
          router.push({
            name: isClient.value ? 'ClientShipmentDetails' : 'AgentShipmentDetails',
            params: {
              shipmentRequestId: notification.shipmentRequestId,
              shipmentId: notification.notifiableId
            }
          });
        }
        break;
      case 'Comment':
      case 'Ticket':
        if (isBackOffice.value) {
          ticketRoute.value = 'BackOfficeTicketDetails';
        } else if (isClient.value) {
          ticketRoute.value = 'ClientTicketDetails';
        } else {
          ticketRoute.value = 'AgentTicketDetails';
        }
        router.push({
          name: ticketRoute.value,
          params: {ticketId: notification.notifiableId}
        });
        break;
      case 'Client':
      case 'Agent':
        if (isBackOffice.value) {
          router.push({
            name: 'CompanyProfile',
            params: {
              companyId: notification.notifiableId
            }
          });
        } else {
          router.push('/');
        }
        break;
      default:
        break;
      }
    };

    const markAllReadLoading = ref(false);
    const readAll = async () => {
      markAllReadLoading.value = true;
      await dispatch('markAllAsRead');
      markAllReadLoading.value = false;
    };

    onMounted(() => dispatch('socketConnect'));
    onUnmounted(() => dispatch('unsubscribeToNotificationChannel', currentUserId));

    return {
      load,
      goToNotification,
      readAll,
      markAllReadLoading,
      notificationsPopOver,
      notifications,
      moment
    };
  }
};
</script>
<style lang="scss" scoped>
.ps {
  height: 200px;
}
.mark-read {
  font-weight: 100;
  border-radius: 25px;
  margin-left: 15px;
}
</style>
<style lang="scss">
.notification-bell {
  padding-top: 15px!important;
  .ant-badge-count{
    background: #F79742!important;
  }
}
.ant-layout-header {
  line-height: 10px!important;
}
</style>
