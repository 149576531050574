<template>
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <template v-for="item in menuItems" :key="item.key">
      <template v-if="!item.children">
        <a-menu-item :key="item.key" @click="toggleCollapsed">
          <router-link :to="{ name: item.pathName }">
            <sdFeatherIcons v-if="item.icon" :type="item.icon" />
            <span>{{ $t(item.i18n) }}</span>
          </router-link>
        </a-menu-item>
      </template>
      <template v-else-if="filteredChildren(item.children).length > 0">
        <a-sub-menu :key="item.key">
          <template v-slot:title>
            <sdFeatherIcons v-if="item.icon" :type="item.icon" />
            <span>{{ $t(item.i18n) }}</span>
          </template>
          <template v-for="child in filteredChildren(item.children)" :key="child.key">
            <a-menu-item @click="toggleCollapsed">
              <router-link :to="{ name: child.pathName }">
                {{ $t(child.i18n) }}
              </router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </template>
  </a-menu>
</template>
<script>
import {
  computed, reactive, ref, toRefs
} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import clientMenuItems from './menuItems/client';
import agentMenuItems from './menuItems/agent';
import backOfficeMenuItems from './menuItems/backOffice';
import {useAbility} from '@casl/vue';
import _ from 'lodash';

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object
  },
  emits: ['hideSideMenu', 'showSideMenu'],
  setup(props, context) {
    const store = useStore();
    const {can} = useAbility();

    const menuItems = computed(() => {
      let items;
      switch (store.state.session.userType ) {
      case 'ClientUser':
        items = clientMenuItems;
        break;
      case 'AgentUser':
        items = agentMenuItems;
        break;
      case 'BackOfficeUser':
        items = backOfficeMenuItems;
        break;
      default:
        return [];
      }
      const permittedItems = _.filter(items, (item) => {
        return can('read', item.abilitySubject) || (item.children && _.some(item.children, (childItem) => can('read', childItem.abilitySubject)));
      });
      if (permittedItems.length === 0) context.emit('hideSideMenu');
      else context.emit('showSideMenu');
      return permittedItems;
    });

    const darkMode = computed(() => store.state.layout.darkMode);
    const mode = ref('inline');
    const {events} = toRefs(props);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav
    } = events.value;

    const state = reactive({
      selectedKeys: [useRoute().name],
      openKeys: []
    });

    const filteredChildren = (children) => {
      return _.filter(children, (child) => can('read', child.abilitySubject));
    };


    return {
      menuItems,
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      filteredChildren
    };
  }
};
</script>
<style lang="scss">
  .ant-tooltip-inner {
    background: #3C50A2!important;
    color: rgba(255, 255, 255, 0.65)!important;
    border-radius: 4px!important;
    i {
      display: none!important;;
    }
  }
  .ant-tooltip-content {
    background: #3C50A2!important;
    color: rgba(255, 255, 255, 0.65)!important;
    border-radius: 8px!important;
  }

  .ant-tooltip-arrow {
    background: #3C50A2!important;
    color: rgba(255, 255, 255, 0.65)!important;
    display: none!important;
  }
  .ant-tooltip-arrow-content {
    background: #3C50A2!important;
    color: rgba(255, 255, 255, 0.65)!important;
    min-width: 160px!important;
  }
  .ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.65)!important;
  }
  .ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom {
    padding-left: 0px!important;
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgb(0 0 0 / 7%);
    transform: translateX(6.53553391px) rotate(45deg);
  }
</style>
