<template>
  <info-wrapper>
    <Notification />
    <div class="nav-author">
      <sd-popover placement="bottomRight" action="click">
        <template v-slot:content>
          <user-drop-down>
            <div class="user-dropdown">
              <figure class="user-dropdown__info">
                <figcaption>
                  <sd-heading as="h5">{{currentUser.firstName + ' ' + currentUser.lastName}}</sd-heading>
                  <p>
                    {{currentUser.type || ''}}
                    {{currentUser._jv?.relationships?.company?.name || ''}}
                  </p>
                </figcaption>
              </figure>
              <ul class="user-dropdown__links">
                <li>
                  <router-link :to="{name: userProfile(), params: {userId: 'me'}}" class="full-width">
                    <sd-feather-icons type="user" /> {{$t('layout.info.profile')}}
                  </router-link>
                </li>
                <li v-if="$can('read', 'company') && !$_.isEmpty(currentUser._jv.relationships.company)">
                  <router-link :to="{name: `${currentUser._jv.relationships.company.type}CompanyProfile`}"
                               class="full-width">
                    <sd-feather-icons type="globe" /> {{$t('layout.info.company')}}
                  </router-link>
                </li>
              </ul>
              <a @click="SignOut" class="user-dropdown__bottomAction" href="#">
                <logout-outlined /> {{$t('layout.info.signout')}}
              </a>
            </div>
          </user-drop-down>
        </template>
        <a to="#" class="head-example">
          <a-avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
        </a>
      </sd-popover>
    </div>
  </info-wrapper>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {
  InfoWrapper, UserDropDown
} from './auth-info-style';
import Notification from './Notification';
import {useStore} from 'vuex';
import {LogoutOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'AuthInfo',
  components: {
    InfoWrapper,
    UserDropDown,
    Notification,
    LogoutOutlined
  },
  setup() {
    const {
      dispatch,
      state,
      getters
    } = useStore();
    const currentUser = computed(() => state.session.currentUser);
    const SignOut = (e) => {
      e.preventDefault();
      const isSocketConnected = getters.socket.isConnected;
      if (isSocketConnected) {
        dispatch('socketDisconnect');
      }

      dispatch('logout');
    };

    const userProfile = () => {
      switch (currentUser.value.type) {
      case 'AgentUser':
        return 'AgentUserProfile';
      case 'ClientUser':
        return 'ClientUserProfile';
      case 'BackOfficeUser':
        return 'BackOfficeUserProfile';
      }
    };

    return {
      currentUser,
      userProfile,
      SignOut
    };
  }
});
</script>

<style scoped>
.full-width {
  width: 100% !important;
}
</style>
